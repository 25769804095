<template>
  <article class="w-full h-full bg-white">
    <header
      class="flex items-center h-16 px-3 border-b-3 border-primary-10 border-opacity-50 lg:hidden"
    >
      <button class="w-4" @click.stop.prevent="onBackClick">
        <Icon name="chevron" />
      </button>
      <h3 class="flex-1">{{ $t("notifications.notifications") }}</h3>
      <div class="w-4"></div>
    </header>
    <header class="hidden justify-between items-center h-16 px-3 lg:flex">
      <div class="text-lg font-bold">
        {{ $t("notifications.notifications") }}
      </div>
    </header>
    <div
      v-if="interactionArray.length > 0"
      class="h-full overflow-y-scroll"
      @click.stop.prevent="onBackClick"
    >
      <div v-if="newInteractionArray.length > 0">
        <div class="px-3 text-left font-bold">
          {{ $t("notifications.new") }}
        </div>
        <div
          v-for="newInteraction in newInteractionArray"
          :key="newInteraction.id"
        >
          <NotificationItem
            :interaction="newInteraction"
            :pro="getPro(newInteraction.pro_id)"
          />
        </div>
      </div>

      <div v-if="oldInteractionArray.length > 0">
        <div
          v-if="newInteractionArray.length > 0"
          class="px-3 pt-3 text-left font-bold"
        >
          {{ $t("notifications.old") }}
        </div>
        <div
          v-for="oldInteraction in oldInteractionArray"
          :key="oldInteraction.id"
        >
          <NotificationItem
            :interaction="oldInteraction"
            :pro="getPro(oldInteraction.pro_id)"
          />
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import NotificationItem from "@/components/header/NotificationItem.vue";
import { mapState, mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "Notifications",

  components: {
    NotificationItem
  },

  computed: {
    ...mapState("interaction", ["interactionArray"]),
    ...mapGetters("interaction", [
      "newInteractionArray",
      "oldInteractionArray"
    ]),
    ...mapState("pro", ["proArray"])
  },

  methods: {
    getPro(proId) {
      return _.find(this.proArray, { id: proId });
    },

    onBackClick() {
      this.$store.commit("closeNotificationPanel");
    }
  }
};
</script>

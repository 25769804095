<template>
  <div
    class="cursor-pointer flex items-center justify-start px-4 py-2 hover:bg-opacity-10 hover:bg-gray-dark transition duration-150"
  >
    <router-link
      :to="{
        name: 'ProjectInteractions',
        params: { chatId: interaction.chat_id }
      }"
      class="flex-1 flex flex-row items-center h-12"
    >
      <div class="flex-0">
        <Avatar :name="company" :imageUrl="avatarUrl" />
        <div class="ml-10 -mt-6">
          <InteractionBadge :type="interaction.interaction_type" />
        </div>
      </div>
      <div class="flex-grow">
        <div class="flex flex-col justify-between mx-4 font-sans text-left">
          <div class="font-sans text-gray-smoke text-xs leading-6">
            <strong class="font-bold text-sm leading-7">
              {{ company }}
            </strong>
          </div>
          <div class="font-sans text-gray-smoke text-xs leading-6">
            {{ typeText }}
          </div>
          <div class="mt-1 text-xs text-gray-elephant leading-6">
            {{ $date(interaction.created_at).fromNow() }}
          </div>
        </div>
      </div>
      <div>
        <Dot v-if="isNew" type="danger" />
      </div>
    </router-link>
  </div>
</template>

<script>
import _ from "lodash";

const INTERACTION_TYPES = {
  PHONE: 1,
  MAIL: 2,
  PROPOSAL: 3
};

export default {
  name: "NotificationItem",

  props: {
    interaction: {
      type: Object,
      defaut: undefined
    },
    pro: {
      type: Object,
      default: undefined
    }
  },

  computed: {
    company() {
      return !_.isNil(this.pro) ? this.pro.company : "";
    },

    avatarUrl() {
      return !_.isNil(this.pro) && !_.isNil(this.pro.avatar_url)
        ? process.env.VUE_APP_CMN_URL + this.pro.avatar_url
        : "";
    },

    typeText() {
      let label = "";
      switch (this.interaction.interaction_type) {
        case INTERACTION_TYPES.PHONE:
          label = "a vu vos coordonnées téléphonique.";
          break;
        case INTERACTION_TYPES.MAIL:
          label = "a vu votre adresse mail.";
          break;
        case INTERACTION_TYPES.PROPOSAL:
          label = "vous a envoyé un message.";
          break;
        default:
          label = "";
      }
      return label;
    },

    isNew() {
      return _.isNil(this.interaction.viewed_at);
    }
  }
};
</script>

import { mapState, mapGetters } from "vuex";

import Header from "@/layouts/partials/Header.vue";
import Sidebar from "@/layouts/partials/Sidebar.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Notifications from "@/components/header/Notifications.vue";

import Tick from "@/assets/images/tick.svg?inline";

export default {
  name: "Page",

  components: {
    Header,
    Sidebar,
    Breadcrumb,
    Notifications,
    Tick
  },

  data() {
    return {
      sidebarIsOpened: false,
      headerHeight: 64,
      showAdContactModal: false,
      showAdContactModalConfirm: false,
      showAdContactMessageModal: false,
      adContactLastname: "",
      adContactFirstname: "",
      adContactTel: "",
      adContactEmail: "",
      adContactMessage: "",
      adTaggedAt: null,
      adId: 0
    };
  },

  computed: {
    ...mapState(["notificationPanelIsOpened"]),
    ...mapState("interaction", ["interactionArray"]),
    ...mapState("ad", ["adArray"]),
    ...mapState("user", ["user"]),
    ...mapGetters("interaction", ["newInteractionArray"]),

    breadcrumb() {
      return this.$route.meta?.breadcrumb?.(this.$route);
      /*
      return this.$router.currentRoute.matched.reduce(
        (breadcrumb: object[], route, idx) => {
          if (route?.meta.breadcrumb) {
            breadcrumb.push(route?.meta.breadcrumb(this.$route));
          }

          return breadcrumb;
        },
        []
      );
      */
    },

    contentOptions() {
      return this.$route.meta?.content?.(this.$route);
    },

    headerOptions() {
      return this.$route.meta?.header?.(this.$route);
    },

    nbNewInteractions() {
      return this.newInteractionArray.length;
    },

    nbAds() {
      return this.adArray.length;
    },

    lastInteractionChatId() {
      return this.interactionArray.length > 0
        ? this.interactionArray[0].chat_id
        : 0;
    }
  },

  methods: {
    toggleSidebarMenu() {
      this.sidebarIsOpened = !this.sidebarIsOpened;
    },

    observeHeaderHeight() {
      const resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
          if (entry.contentBoxSize) {
            const contentBoxSize = entry.contentBoxSize;
            this.headerHeight = contentBoxSize[0]
              ? contentBoxSize[0].blockSize
              : contentBoxSize.blockSize;
          } else {
            this.headerHeight = entry.contentRect.height;
          }
        }
      });

      resizeObserver.observe(this.$refs.headerWrapper);
    },

    handleBlurNotificationPanel(event) {
      const relatedTarget = event.relatedTarget;
      if (event && event.currentTarget.contains(relatedTarget)) return;

      this.$store.commit("closeNotificationPanel");
    },

    onNotificationPanelIsOpened(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.notificationPanel.focus();
        });
      }
    },

    initAdContactModal(adId) {
      this.adId = adId;
      this.adContactLastname = this.user.last_name;
      this.adContactFirstname = this.user.first_name;
      this.adContactEmail = this.user.email;
      this.adContactTel = this.user.phone;
      this.adContactMessage = '';
      this.showAdContactModal = true;
    },

    initAdContactMessageModal(taggedAt, message) {
      this.adTaggedAt = taggedAt
      this.adContactMessage = message;
      this.showAdContactMessageModal = true;
    },

    onlyNumber(event) {
      if (!/\d/.test(event.key)) return event.preventDefault();
    },

    async adCallMeBack() {
      if (this.adContactLastname != '' && this.adContactFirstname != '' && this.adContactTel != '') {
        this.showAdContactModal = false;
        this.user.last_name = this.adContactLastname;
        this.user.first_name = this.adContactFirstname;
        this.user.phone = this.adContactTel;
        this.$store.dispatch("user/hubspotCallBack", {
          hubspot_event: "ad",
          last_name: this.adContactLastname,
          first_name: this.adContactFirstname,
          tel: this.adContactTel,
          ad_id: this.adId,
          message: this.adContactMessage
        });
        this.$store.dispatch('ad/updateAd', { id: this.adId, body: { tag: 'contact', message: this.adContactMessage } });
        this.showAdContactModalConfirm = true;
      }
    }
  },

  watch: {
    notificationPanelIsOpened: "onNotificationPanelIsOpened"
  },

  created() {
    //this.$nextTick(this.observeHeaderHeight);
    this.$root.$on("openAdContactModal", (adId) => {
      this.initAdContactModal(adId);
    });
    this.$root.$on("openAdContactMessageModal", (taggedAt, message) => {
      this.initAdContactMessageModal(taggedAt, message);
    });
  }
};

<template>
  <div
    class="w-full h-full py-3 flex flex-col justify-between items-center bg-primary-20 lg:bg-gradient-to-b from-lagoon-start via-lagoon-via to-lagoon-stop sidebar">
    <div class="w-full flex flex-col justify-start items-center">
      <div class="flex flex-row w-full h-8 mb-8 px-4 lg:hidden">
        <Button size="lg" appearance="invert" icon @click="toggleSidebarMenu">
          <Icon name="bars" class="text-white" />
        </Button>
      </div>

      <router-link :to="{
        name: 'Project'
      }"
        class="hidden flex-shrink-0 justify-center items-center w-24 h-24 mb-9 bg-white bg-opacity-75 rounded-full hover:bg-opacity-50 focus:bg-opacity-50 focus:outline-none lg:flex">
        <LogoCherchemonnid class="w-1/2" />
      </router-link>

      <nav class="w-full px-4 font-lato text-left lg:px-0" @click="toggleSidebarMenu">
        <div class="mb-4 lg:mb-8">
          <h3
            class="mb-4 text-xl font-black text-secondary-70 leading-6 uppercase lg:mb-0 px-2 lg:p-4 lg:text-base lg:font-bold lg:text-white lg:border-b-2 lg:border-white lg:border-opacity-25">
            {{
              $t(
                isBuyer
                  ? hasPropertyForSale
                    ? "property.buy_sell"
                    : "property.buy"
                  : "property.rent"
              )
            }}
          </h3>
          <ul class="text-md font-bold text-secondary-60 lg:text-sm lg:text-white">
            <li>
              <router-link :to="{
                    name: 'Project'
                  }" exact
                class="w-full block py-3 px-2 lg:px-4 lg:border-r-8 lg:border-white lg:border-opacity-0 lg:hover:border-opacity-50">
                <Icon name="house" size="lg" class="mr-3" />
                {{ $t("menu.summary") | capitalize }}
              </router-link>
            </li>
            <li class="lg:hidden">
              <router-link :to="{
                name: 'ProjectChats'
              }"
                class="w-full block py-3 px-2 lg:px-4 lg:border-r-8 lg:border-white lg:border-opacity-0 lg:hover:border-opacity-50">
                <Icon name="comments" size="lg" class="mr-3" />
                {{ $t("menu.interactions") | capitalize }}
                <Badge v-if="nbNewInteractions > 0" type="danger" :count="nbNewInteractions" class="ml-2" />
              </router-link>
            </li>
            <li class="hidden lg:block">
              <router-link :to="{
                name: 'ProjectInteractions',
                params:
                  lastInteractionChatId > 0
                    ? { chatId: lastInteractionChatId }
                    : {}
              }"
                class="w-full block py-3 px-2 lg:px-4 lg:border-r-8 lg:border-white lg:border-opacity-0 lg:hover:border-opacity-50">
                <Icon name="comments" size="lg" class="mr-3" />
                {{ $t("menu.interactions") | capitalize }}
                <Badge v-if="nbNewInteractions > 0" type="danger" :count="nbNewInteractions" class="ml-20" />
              </router-link>
            </li>
            <li v-if="nbAds > 0">
              <router-link :to="{
                name: 'AllAds'
              }" exact
                class="w-full block py-3 px-2 lg:px-4 lg:border-r-8 lg:border-white lg:border-opacity-0 lg:hover:border-opacity-50">
                <Icon name="for-sale" size="lg" class="mr-3" />
                {{ $t("menu.ads") | capitalize }}
                <Badge type="success" :count="nbAds" class="ml-20" />
              </router-link>
            </li>
            <li class="lg:hidden">
              <router-link :to="{
                name: 'Settings'
              }"
                class="w-full block py-3 px-2 lg:px-4 lg:border-r-8 lg:border-white lg:border-opacity-0 lg:hover:border-opacity-50">
                <Icon name="cog" size="lg" class="mr-3" />
                {{ $t("menu.settings") | capitalize }}
              </router-link>
            </li>
          </ul>
          <h3
            class="mt-8 mb-4 text-xl font-black text-secondary-70 leading-6 uppercase lg:mb-0 lg:p-4 lg:text-base lg:font-bold lg:text-white lg:border-b-2 lg:border-white lg:border-opacity-25">
          </h3>
          <ul class="text-md font-bold text-secondary-60 lg:text-sm lg:text-white">
            <li>
              <a href="https://blog.cherchemonnid.com/" target="blank"
                class="w-full block py-3 px-2 lg:px-4 lg:border-r-8 lg:border-white lg:border-opacity-0 lg:hover:border-opacity-50"
                @click="trackBlogClick()">
                <Icon name="vertical-split" size="lg" class="mr-3" />
                Blog
              </a>
            </li>
            <li>
              <a href="https://www.cherchemonnid.com/contact" target="blank"
                class="w-full block py-3 px-2 lg:px-4 lg:border-r-8 lg:border-white lg:border-opacity-0 lg:hover:border-opacity-50">
                <Icon name="info" size="lg" class="mr-3" />
                Une question ?
              </a>
            </li>
          </ul>
          <h3 class="mt-8 mb-4 text-xl font-black text-secondary-70 leading-6 uppercase lg:hidden"></h3>
          <ul class="text-md font-bold text-secondary-60 lg:hidden">
            <li>
              <a class="w-full block py-3 px-2" @click="signout">
                <Icon name="sign-out" size="md" class="mr-2" />Déconnexion
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <div class="w-full flex flex-col justify-end items-center text-secondary-50">
      <div class="flex items-center mb-2">
        <a href="https://www.facebook.com/lemaglogement/" target="blank">
          <LogoFacebook class="w-8 mx-1 fill-current hover:text-secondary-90" />
        </a>
        <a href="https://www.instagram.com/cherchemonnid/?hl=fr" target="blank">
          <LogoInstagram class="w-8 mx-1 fill-current hover:text-secondary-90" />
        </a>
        <a href="https://www.linkedin.com/company/cherchemonnid/" target="blank">
          <LogoLinkedin class="w-8 mx-1 fill-current hover:text-secondary-90" />
        </a>
        <a href="https://twitter.com/cherchemonnid" target="blank">
          <LogoTwitter class="w-8 mx-1 fill-current hover:text-secondary-90" />
        </a>
      </div>
      <div class="text-sm font-bold">
        ©{{ $date().format("YYYY") }} cherchemonnid.com
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

import LogoCherchemonnid from "@/assets/images/logo-cherchemonnid.svg?inline";
import LogoFacebook from "@/assets/images/logo-facebook.svg?inline";
import LogoInstagram from "@/assets/images/logo-instagram.svg?inline";
import LogoLinkedin from "@/assets/images/logo-linkedin.svg?inline";
import LogoTwitter from "@/assets/images/logo-twitter.svg?inline";

export default {
  name: "Sidebar",

  components: {
    LogoCherchemonnid,
    LogoFacebook,
    LogoInstagram,
    LogoLinkedin,
    LogoTwitter
  },

  props: {
    nbAds: {
      type: Number,
      default: 0
    },
    nbNewInteractions: {
      type: Number,
      default: 0
    },

    lastInteractionChatId: {
      type: Number,
      default: 0
    }
  },

  computed: {
    ...mapState("user", ["isBuyer", "hasPropertyForSale"]),
    ...mapGetters("user", ["gtmProfile"])
  },

  methods: {
    ...mapActions("user", ["logout"]),

    toggleSidebarMenu() {
      this.$emit("toggleSidebarMenu");
    },

    trackBlogClick() {
      this.$gtm.trackEvent({
        event: "user-blog",
        eventCategory: "External Link",
        eventAction: "Blog",
        eventLabel: this.gtmProfile,
        eventValue: "",
        noninteraction: false
      });
    },

    signout() {
      this.logout().then(() => {
        window.location.replace(process.env.VUE_APP_CMN_URL + "/deconnexion");
      });
    }
  }
};
</script>

<style lang="postcss" scoped>
.sidebar {
  @screen lg {
    background-size: 100% max(100vh, 1200px);
  }
}

nav .router-link-active {
  @apply text-secondary-80;

  @screen lg {
    @apply bg-gradient-to-r from-dust-start to-dust-stop bg-opacity-25 border-opacity-100 text-white;

    filter: blur(10);
  }
}
</style>

<template>
  <div class="w-full min-h-screen flex flex-row">
    <div class="w-full min-h-screen">
      <div class="z-10 fixed left-0 right-0">
        <router-view name="header" />
      </div>
      <main
        class="w-full min-h-full flex flex-col pt-16 bg-white lg:bg-gray-light"
      >
        <div
          class="flex-1 flex flex-col w-full"
          :class="
            contentOptions === undefined || contentOptions.margin === true
              ? 'max-w-6xl mx-auto'
              : ''
          "
        >
          <slot />
        </div>
      </main>
    </div>
    <div
      ref="notificationPanel"
      v-show="notificationPanelIsOpened"
      tabindex="-1"
      class="z-30 absolute top-0 right-0 focus:outline-none lg:w-72 lg:left-auto lg:m-4 lg:shadow-header h-screen"
      @blur.self.stop="handleBlurNotificationPanel"
    >
      <Notifications />
    </div>
  </div>
</template>

<script>
import Page from "./PageMixin";

export default {
  name: "NoSideBarPage",

  mixins: [Page]
};
</script>

<style scoped></style>

<template>
  <div class="w-full min-h-screen flex flex-row">
    <div
      class="z-20 fixed top-0 bottom-0 right-0 flex-shrink-0 w-full min-h-screen transform transition-transform duration-300 ease-in-out lg:left-0 lg:right-auto lg:w-64 lg:translate-x-0"
      :class="sidebarIsOpened ? 'translate-x-0' : '-translate-x-full'">
      <Sidebar :nb-new-interactions="nbNewInteractions" :nb-ads="nbAds" :last-interaction-chat-id="lastInteractionChatId"
        @toggleSidebarMenu="toggleSidebarMenu" />
    </div>
    <div class="w-full min-h-screen">
      <div class="z-10 fixed left-0 right-0 lg:pl-64" ref="headerWrapper">
        <router-view name="header" @toggleSidebarMenu="toggleSidebarMenu" />
      </div>
      <main class="w-full min-h-full flex flex-col pt-16 bg-gray-light lg:pl-64"
        :style="`padding-top: ${headerHeight}px`">
        <div class="flex-1 flex flex-col w-full" :class="contentOptions === undefined || contentOptions.margin === true
            ? 'max-w-6xl mx-auto px-4 py-8 lg:px-12'
            : ''
          ">
          <div class="hidden mb-2 lg:block" :class="contentOptions === undefined || contentOptions.margin === true
              ? ''
              : 'px-12 py-8'
            ">
            <router-view name="breadcrumb" />
            <!-- <Breadcrumb :breadcrumb="breadcrumb" /> -->
          </div>
          <slot />
        </div>
      </main>
    </div>
    <div ref="notificationPanel" v-show="notificationPanelIsOpened" tabindex="-1"
      class="z-30 absolute top-0 right-0 focus:outline-none lg:w-72 lg:left-auto lg:m-4 lg:shadow-header h-screen"
      @blur.self.stop="handleBlurNotificationPanel">
      <Notifications />
    </div>
    <Modal
      v-show="showAdContactModal"
      type="success"
      :title="$t('pages.ads.modal.title')"
      :actions="{
        cancelVisible: true,
        cancelLabel: 'Annuler',
        actionVisible: true,
        actionLabel: $t('pages.ads.modal.action'),
        backdropClickEnabled: false
      }"
      @action="adCallMeBack()"
      @cancel="showAdContactModal = false"
    >
      <div class="md:flex md:w-3/4 mx-auto mt-4">
        <div class="md:w-1/3 mt-2 text-left md:text-xl">
          Nom :
        </div> 
        <div class="bg-gray-light rounded-xxl md:w-2/3 mt-2 px-2">
          <input
          type="text"
            v-model="adContactLastname"
            :placeholder="adContactLastname"
            class="self-center bg-transparent w-full outline-none px-2 md:text-xl"
          />
        </div>
      </div>
      <div class="md:flex md:w-3/4 mx-auto mt-2">
        <div class="md:w-1/3 mt-2 text-left md:text-xl">
          Prénom :
        </div> 
        <div class="bg-gray-light rounded-xxl md:w-2/3 mt-2 px-2">
          <input
          type="text"
            v-model="adContactFirstname"
            :placeholder="adContactFirstname"
            class="self-center bg-transparent w-full outline-none px-2 md:text-xl"
          />
        </div>
      </div>
      <div class="md:flex md:w-3/4 mx-auto mt-2">
        <div class="md:w-1/3 mt-2 text-left md:text-xl">
          Email :
        </div> 
        <div class="bg-gray-light rounded-xxl md:w-2/3 mt-2 px-2">
          <input
          type="text"
            v-model="adContactEmail"
            :placeholder="adContactEmail"
            class="self-center bg-transparent w-full outline-none px-2 md:text-xl"
            disabled
          />
        </div>
      </div>
      <div class="md:flex md:w-3/4 mx-auto mt-2">
        <div class="md:w-1/3 mt-2 text-left md:text-xl">
          Tel :
        </div> 
        <div class="bg-gray-light rounded-xxl md:w-2/3 mt-2 px-2">
          <input
          type="text"
            v-model="adContactTel"
            :placeholder="adContactTel"
            @keypress="onlyNumber"
            class="self-center bg-transparent w-full outline-none px-2 md:text-xl"
          />
        </div>
      </div>
      <div class=" mt-4 md:mt-10 ml-4 text-left">
        {{ $t('pages.ads.modal.paragraph') }}
      </div>
      <div class="md:flex w-full mx-auto mt-2"> 
        <div class="bg-gray-light rounded-xxl mt-2 px-2">
          <textarea
            cols="100"
            rows="5"
            maxlength="600"
            v-model="adContactMessage"
            :placeholder="$t('pages.ads.modal.message_placeholder')"
            class="self-center bg-transparent w-full outline-none py-4 px-2"
          />
        </div>
      </div>
    </Modal>
    <Modal
        v-show="showAdContactModalConfirm"
        type="success"
        :title="$t('pages.ads.modal_confirm.title')"
        :actions="{
          cancelVisible: false,
          cancelLabel: 'Annuler',
          actionVisible: true,
          actionLabel: $t('pages.ads.modal_confirm.action'),
          backdropClickEnabled: false
        }"
        @action="showAdContactModalConfirm = false"
      >
        <div class="md:flex w-3/4 mx-auto mt-6">
          <div class="hidden md:block w-1/4 mt-2">
            <Tick class="w-16 h-16"/>
          </div> 
          <div class="md:w-3/4 text-xl self-center">
            <div>{{ $t('pages.ads.modal_confirm.paragraph_1') }}</div>
            <div>{{ $t('pages.ads.modal_confirm.paragraph_2') }}</div>
          </div>
        </div>
        <div class="mt-8">
          {{ $t('pages.ads.modal_confirm.paragraph_3') }}
        </div>
    </Modal>
    <Modal
        v-show="showAdContactMessageModal"
        type="success"
        :title="$t('pages.ads.modal_message.title')"
        :actions="{
          cancelVisible: false,
          cancelLabel: 'Annuler',
          actionVisible: true,
          actionLabel: $t('pages.ads.modal_confirm.action'),
          backdropClickEnabled: false
        }"
        @action="showAdContactMessageModal = false"
      >
        <div class="md:flex w-3/4 mx-auto mt-6">
          <div class="hidden md:block w-1/4 mt-2">
            <Tick class="w-16 h-16"/>
          </div> 
          <div class="md:w-3/4 self-center">
            <div>{{ $t('pages.ads.modal_message.paragraph_1', { at: new Date(adTaggedAt).toLocaleDateString("fr-FR", { year: "numeric", month: "numeric", day: "numeric" }) }) }}</div>
          </div>
        </div>
        <div v-if="adContactMessage != null" class="text-left">
          <div class="mt-8 p-2">
            {{ $t('pages.ads.modal_message.paragraph_2') }}
          </div>
          <div class="mt-2 w-full py-2 px-4 bg-gray-light rounded-xxl border-gray-light border-2">
            <span v-html="adContactMessage.replace('\n', '<br>')"></span>
          </div>
        </div>
    </Modal>
  </div>
</template>

<script>
import PageMixin from "./PageMixin";

export default {
  name: "Page",

  mixins: [PageMixin]
};

</script>

<style scoped></style>
